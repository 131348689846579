import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {TournamentEntity} from "../../../lambda/common/Entities";

interface IProviderProps {
    children: ReactNode;
}

interface TournamentContextData {
    tournamentEntity: TournamentEntity | undefined;
    loading: boolean
    isReservationOpened: () => boolean;
    isReservationClosed: () => boolean;
    isBooked: () => boolean;
    setLoading: (loading: boolean) => void;
    setTournamentEntity: (tournamentEntity: TournamentEntity) => void;
}

const TournamentContext = createContext<TournamentContextData>({} as TournamentContextData);

const TournamentProvider = ({children}: IProviderProps) => {
    const [tournamentEntity, setTournamentEntity] = useState<TournamentEntity | undefined>(undefined);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true)
        fetch(process.env.REACT_APP_API_URL + "/api/public/tournament/active", {
            method: "GET"
        }).then(response => {
            if (!response || response.status !== 200) {
                window.alert("Fehler beim Bestellprozess.")
            }
            response.text().then(value => {
                if (value && value != "") {
                    let tournamentEntity = JSON.parse(value) as TournamentEntity;
                    setTournamentEntity(tournamentEntity)
                }
            })
        }).finally(() => {
            setLoading(false)
        });
    }, [])

    function isReservationClosed() {
        let reservationClosed = !loading && tournamentEntity !== undefined && new Date(tournamentEntity!.tournament.preregistrationEndDate).getTime() <= new Date().getTime();
        console.log("Reservation closed: " + reservationClosed)
        return reservationClosed
    }

    function isReservationOpened() {
        let reservationOpened = !loading && tournamentEntity !== undefined && new Date(tournamentEntity!.tournament.preregistrationDate).getTime() <= new Date().getTime();
        console.log("Reservation opened: " + reservationOpened)
        return reservationOpened
    }

    function isBooked() {
        let participantCount = tournamentEntity?.onlineRegistrationCount;
        participantCount = participantCount ? participantCount : 0
        let maxPreRegistrationCount = tournamentEntity?.tournament.maxPreRegistrationCount!;
        let tournamentBooked = participantCount >= maxPreRegistrationCount;
        console.log("Tournament booked: " + tournamentBooked)
        return tournamentBooked;
    }

    return (
        <TournamentContext.Provider value={{
            tournamentEntity,
            loading,
            isBooked,
            isReservationClosed,
            isReservationOpened,
            setLoading,
            setTournamentEntity
        }}>
            {children}
        </TournamentContext.Provider>
    );
}

export default TournamentProvider;

export const useTournamentContext = () => useContext(TournamentContext);