import React from 'react';
import MainPage from "./pages/MainPage";
import "./App.css"
import Imprint from "./pages/Imprint";
import OrderTickets from "./pages/OrderTickets";
import {Col, Container, Image, Nav, Navbar, Row} from "react-bootstrap";
import {useTournamentContext} from "./context/TournamentContext";
import Skeleton from "react-loading-skeleton";
import OrderDone from "./pages/OrderDone";

export default function App() {

    const tournamentContext = useTournamentContext()

    const showMainPage = () => {
        if (window.location.pathname === "/") {
            return <MainPage/>
        }
    }
    const showImprint = () => {
        if (window.location.pathname === "/imprint") {
            return <Imprint/>
        }
    }
    const orderTickets = () => {
        if (tournamentContext.tournamentEntity && window.location.pathname === "/order") {
            return <OrderTickets/>
        }
    }
    const orderDone = () => {
        if (tournamentContext.tournamentEntity && window.location.pathname === "/orderDone") {
            return <OrderDone/>
        }
    }

    return (
        <>
            <Navbar variant="light" expand="lg" className="bg-body-tertiary mb-2">
                <Container fluid={true}>
                    <Navbar.Brand href="/"><Image className="mx-lg-3" width="50px" src="/logo-ost.png" fluid={true}
                                                  roundedCircle={true}></Image>"Frohsinn" Greven-Ost 1925
                        e.V.</Navbar.Brand>
                </Container>
            </Navbar>
            {tournamentContext.loading &&
                <Container>
                    <Row className="justify-content-center align-items-center">
                        <Col>
                            <Skeleton/>
                        </Col>
                    </Row>
                </Container>
            }
            {showMainPage()}
            {showImprint()}
            {orderTickets()}
            {orderDone()}
            <Navbar fixed="bottom" className={"bg-body-tertiary justify-content-center"}>
                <div className="container-fluid-nav text-center">
                    <a href="/imprint" target="_self" className="text-center">Impressum</a>
                </div>
            </Navbar>
        </>
    );
}