import React, {useState} from "react";
import {Alert, Col, Container, Image, ListGroup, ListGroupItem, Row, Spinner} from "react-bootstrap";
import {useTournamentContext} from "../context/TournamentContext";
import Skeleton from "react-loading-skeleton";
import useInterval from "../hooks/useInterval";
import {OrderStatusEntity} from "../../../lambda/common/Entities";
import QRCode from "react-qr-code";


function OrderDone() {

    const tournamentContext = useTournamentContext()
    const [waitingForOrderApproval, setWaitingForOrderApproval] = useState(true)
    const [pollCount, setPollCount] = useState(0)
    const [errorMessage, setErrorMessage] = useState("")
    const [delay, setDelay] = useState(5000);
    const [isPolling, setIsPolling] = useState(true);
    const [resultData, setResultData] = useState<OrderStatusEntity[]>([])

    const pollingCallback = async () => {
        setErrorMessage("")
        console.log("Polling..." + pollCount)
        setPollCount(pollCount + 1)
        const orderId = new URLSearchParams(window.location.search).get("orderId")
        if (!orderId || orderId === "") {
            setErrorMessage("Keine Bestellnummer angegeben")
            stopPollingForOrder()
            return
        }
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/api/public/order/" + orderId, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                },
            })
            if (!response || response.status !== 200) {
                console.log("Got not response or status != 200")
                if (pollCount > 20) {
                    stopPollingForOrder()
                    setErrorMessage("Wir konnten Ihre Daten zu dieser Bestellung nicht abrufen. Bitte schauen Sie in Ihren E-Mails nach oder wenden sie sich an info@doko-ost.de.")
                }
                return
            }

            let responseJson = await response.json();
            if (responseJson as [] && (responseJson as []).length > 0) {
                setResultData(responseJson)
                setWaitingForOrderApproval(false)
                stopPollingForOrder()
            }
        } catch (e) {
            console.error(e)
            if (pollCount > 15) {
                stopPollingForOrder()
                setErrorMessage("Wir konnten Ihre Daten zu dieser Bestellung nicht abrufen. Bitte schauen Sie in Ihren E-Mails nach oder wenden sie sich an info@doko-ost.de.")
            }
        }
    };

    useInterval(pollingCallback, isPolling ? delay : 50000000);

    const stopPollingForOrder = () => {
        setIsPolling(false)
        setPollCount(0)
        console.log("Stop polling...")
    };

    return (
        <Container fluid={false}>
            {errorMessage != "" && <Alert variant={"danger"}>{errorMessage}</Alert>}
            <Row>
                <Col>
                    <p className={"display-3"}>Bestellung erfolgreich abgeschlossen
                    </p>
                </Col>
            </Row>
            {waitingForOrderApproval &&
                <>
                    <Row>
                        <Col>
                            <p className={"display-6"}>Bitte gedulden Sie sich noch einen Moment während wir auf die
                                Bestätigung Ihrer Zahlung warten.<br/>Die Information zu dieser Bestellung werden jedem
                                Teilnehmer per E-Mail bereitgestellt.</p>
                        </Col>
                    </Row>
                    <Row className={"d-flex justify-content-center"}>
                        <Col className={"col-auto text-center"}>
                            <Spinner variant={"primary"} animation={"grow"}/>
                        </Col>
                        <Col className={"col-auto text-center"}>
                            <Spinner variant={"primary"} animation={"grow"}/>
                        </Col>
                        <Col className={"col-auto text-center"}>
                            <Spinner variant={"primary"} animation={"grow"}/>
                        </Col>
                    </Row>
                </>
            }
            {!waitingForOrderApproval &&
                <>
                    <Row>
                        <Col>
                            <h3>Zusammenfassung Ihrer Bestellung</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ListGroup>
                                {resultData.map(value => {
                                    return <ListGroup.Item key={value.entityId} as="li">
                                        <Row>
                                            <Col className={"col-6 mt-2"}>
                                                <div
                                                    className="fw-bold">{value.user.firstname} {value.user.lastname}</div>
                                                {value.user.email}<br/>
                                                Online Anmeldecode: {value.participantOnlineCode}<br/>
                                            </Col>
                                            <Col className={"col-6 mt-2"}>
                                                <div className="fw-bold">QR-Code für die Anmeldung</div>
                                                <QRCode size={100} value={value.participantOnlineCode}></QRCode>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className={"col-6 mt-2"}>
                                                <a href={"/api/public/participant/" + btoa(value.entityId) + "/apple"}><Image
                                                    height={"40px"} src="/appleWallet.png"></Image></a>
                                            </Col>
                                            <Col className={"col-6 mt-2"}>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                })

                                }
                            </ListGroup>
                        </Col>
                    </Row>
                </>
            }
            <Row className={"mt-5 text-center"}>
                <Col>
                    <h4>Vielen Dank für Ihre Bestellung. Wir freuen uns auf Ihre
                        Teilnahme.</h4>
                </Col>
            </Row>
        </Container>
    );
}

export default OrderDone;