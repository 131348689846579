import React from "react";
import {Container} from "react-bootstrap";


function Imprint() {

    return (
        <Container fluid={false}>
            <h1>Datenschutzhinweise gemäß Art. 13 DSGVO</h1>
            <h2>Verantwortliche Stelle</h2>
            <p>Schützenverein „Frohsinn“ Greven-Ost 1925 e.V.<br/>
                Montargisstraße 30<br/>
                48268 Greven</p>
            <p><a href="mailto:"></a></p>
            <p>Bei Fragen zum Datenschutz stehen wir Ihnen unter <a
                href="mailto:info@frohsinn-ost.de">info@frohsinn-ost.de</a> oder unter der oben angegebenen postalischen
                Anschrift zur Verfügung.</p>
            <h2>Datenverarbeitung auf unserer Webseite und weiteren Online-Angeboten</h2>
            <h3>Logfiles beim Aufruf unserer Webseite</h3>
            <p>Bei Aufruf unserer Webseite werden zur Analyse von Fehlern und Sicherheitsvorfällen Logfiles erstellt.
                Diese bleiben nur für einige Tage bis Wochen gespeichert und werden anschließend automatisch
                gelöscht.</p>
            <p>Für das Hosting unserer Webseite verwenden wir Amazon Web Services als externen Dienstleister. Die
                Speicherung der Logfiles erfolgt dort.</p>
            <h3>Abonnement unseres Newsletter</h3>
            <p>Wenn Sie auf unserer Vereinswebseite den <ins>Newsletter</ins> abonniert haben, verwenden wir Ihre
                E-Mail-Adresse, um Ihnen regelmäßig den Newsletter zuzusenden und Sie mit Wissenswertem über den Verein
                zu versorgen. Die Rechtsgrundlage für den Newsletter-Versand ist Ihre Einwilligung (Artikel 6 Absatz 1
                Satz 1 a) DSGVO). Die Einwilligung können Sie jederzeit widerrufen, um den Newsletter nicht weiter zu
                bekommen. Dazu können Sie unten in der Newsletter-E-Mail auf „abbestellen“ oder „unsubscribe“ klicken.
                Oder Sie schreiben uns an <a href="mailto:info@frohsinn-ost.de">info@frohsinn-ost.de</a> oder per
                Post.<br/>
                Die E-Mail- Adresse, die Sie für den Versand des Newsletters angegeben haben, wird<br/>
                gelöscht, sobald Sie Ihre Einwilligung in den Newsletter widerrufen, es sei denn, dass diese
                E-Mail-Adresse auch für andere Kommunikation als zum Newsletter-Versand zwischen dem Verein und Ihnen
                verwendet wird.
            </p>
            <h2>Datenverarbeitung im Rahmen unserer Vereinsarbeit</h2>
            <h3>Mitgliederverwaltung</h3>
            <p>Mitgliedsdaten (Durchführung von Doppelkopf Turnieren) werden von den jeweiligen Funktionsträger:innen
                unseres Vereins nur für die ihnen zugeordnete Aufgabenerfüllung verarbeitet. Im Einzelnen bedeutet
                dies:</p>
            <ul>
                <li>Wenn der <ins>Vorstand</ins> Mitgliedsdaten benötigt, um seine Aufgaben zu erledigen, darf er auf
                    alle hierfür erforderlichen Mitgliedsdaten zugreifen. Dazu gehört insbesondere Planung und
                    Durchführung von Doppelkopfturnieren.
                </li>
                <li>Der/die Kassenwart:in verarbeitet die Mitgliedsdaten, die für den Einzug der Mitgliedsbeiträge,
                    der/die <ins>Kassenprüfer:in</ins> verarbeitet die Mitgliedsdaten, die für die Kassenprüfung
                    relevant sind. Dies sind Vorname, Nachname, postalische Anschrift und Bankverbindung mit
                    Zahlungsdaten sowie ggf. Zugriff auf die Lastschriftsverfahrensgenehmigung inklusive Unterschrift,
                    sofern das Mitglied dem Verein ein Lastschriftmandat erteilt hat.
                </li>
                <li>Die <ins>Vereinsgeschäftsstelle</ins> verarbeitet die Mitgliedsdaten zur Mitgliedsverwaltung und
                    -betreuung.
                </li>
            </ul>
            <p>Zweck für die Verarbeitung der Mitgliedsdaten ist die Verfolgung des Vereinszwecks und die -verwaltung.
                Rechtsgrundlage ist die Vereinsmitgliedschaft (Artikel 6 Absatz 1 b) DSGVO).</p>
            <p>Die aktuellen Mitgliedsdaten werden für die Dauer der Mitgliedschaft und darüber hinaus für 3 Jahre nach
                letzter Teilnahme an einem Doppelkopfturnier gespeichert.</p>
            <h2>Betroffenenrechte</h2>
            <p>Wenn wir personenbezogene Daten von Ihnen verarbeiten, haben Sie folgende Betroffenenrechte:</p>
            <ul>
                <li>ein Recht auf Auskunft über die verarbeiteten Daten und auf Kopie,</li>
                <li>ein Berichtigungsrecht, wenn wir falsche Daten über Sie verarbeiten,</li>
                <li>ein Recht auf Löschung, es sei denn, dass noch Ausnahmen greifen, warum wir die Daten noch
                    speichern, also zum Beispiel Aufbewahrungspflichten oder Verjährungsfristen
                </li>
                <li>ein Recht auf Einschränkung der Verarbeitung,</li>
                <li>ein jederzeitiges Recht, Einwilligungen in die Datenverarbeitung zu widerrufen,</li>
                <li>ein Widerspruchsrecht gegen eine Verarbeitung im öffentlichen oder bei berechtigtem Interesse,</li>
                <li>ein Recht auf Datenübertragbarkeit,</li>
                <li>ein Beschwerderecht bei einer Datenschutz-Aufsichtsbehörde, wenn Sie finden, dass wir Ihre Daten
                    nicht ordnungsgemäß verarbeiten. Für unseren Verein ist die Landesbeauftragte für Datenschutz und
                    Informationsfreiheit Nordrhein-Westfalen zuständig. Wenn Sie sich in einem anderen Bundesland oder
                    nicht in Deutschland aufhalten, können Sie sich aber auch an die dortige Datenschutzbehörde wenden.
                </li>
            </ul>
        </Container>
    );
}

export default Imprint;