import React from "react";
import {Button, Card, Col, Container, Image, Row} from "react-bootstrap";
import {useTournamentContext} from "../context/TournamentContext";


function MainPage() {

    const tournamentContext = useTournamentContext()
    const dayNames = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"]

    return (
        <Container fluid={false}>
            <Row className="mt-2 justify-content-center align-items-center">
                <Col className="col-md-auto text-center">
                    <Image width="200px" src="/logo-ost.png" fluid={true} roundedCircle={true}></Image>
                </Col>

            </Row>
            {tournamentContext.tournamentEntity && !tournamentContext.isReservationOpened() &&
                <Row className="justify-content-center align-items-center">
                    <Col className="justify-content-center align-items-center">
                        <h1 className="text-center">Die Voranmeldung für
                            das {tournamentContext.tournamentEntity?.tournament.displayName} ist ab dem {new Date(tournamentContext.tournamentEntity!.tournament.preregistrationDate).toLocaleDateString("de-DE", {
                                "day": "2-digit",
                                "year": "numeric",
                                "month": "2-digit",
                            })} möglich.</h1>
                    </Col>
                </Row>
            }
            {tournamentContext.tournamentEntity && tournamentContext.isReservationClosed() && tournamentContext.isReservationOpened() &&
                <Row className="justify-content-center align-items-center">
                    <Col className="justify-content-center align-items-center">
                        <h1 className="text-center">Die Voranmeldung für
                            das {tournamentContext.tournamentEntity?.tournament.displayName} ist nicht mehr
                            möglich.</h1>
                    </Col>
                </Row>
            }
            {tournamentContext.tournamentEntity && tournamentContext.isBooked() && tournamentContext.isReservationOpened() && !tournamentContext.isReservationClosed() &&
                <Row className="justify-content-center align-items-center">
                    <Col className="justify-content-center align-items-center">
                        <h1 className="text-center">Die im Vorverkauf erhältlichen Karten für
                            das {tournamentContext.tournamentEntity?.tournament.displayName} sind
                            bereits <strong>ausverkauft.</strong><br/>Karten erhalten Sie weiterhin an der Abendkasse.
                        </h1>
                    </Col>
                </Row>
            }
            {tournamentContext.tournamentEntity && tournamentContext.isReservationOpened() && !tournamentContext.isBooked() && !tournamentContext.isReservationClosed() &&
                <>
                    <Row className="justify-content-center align-items-center mt-3">
                        <Col className={"col-12"}>
                            <Card className={"shadow-sm"}>
                                <Card.Body>
                                    <Row>
                                        <Col className={"mainPageCardDateRow col-sm-auto col-12"}>
                                            <Row>
                                                <Col>
                                                    <h2>{new Date(tournamentContext.tournamentEntity?.tournament.date!).getDate() + "."}</h2>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h4>{new Date(tournamentContext.tournamentEntity?.tournament.date!).toLocaleString('de-DE', {month: 'long'}).substring(0, 3) + " " + new Date(tournamentContext.tournamentEntity?.tournament.date!).getFullYear()}</h4>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h4>{dayNames[new Date(tournamentContext.tournamentEntity?.tournament.date!).getDay()].substring(0, 2) + " " + tournamentContext.tournamentEntity?.tournament.time + " Uhr"}</h4>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="clickable mainPageCardTournamentRow"
                                             onClick={() => window.location.href = "/order"}>
                                            <Row>
                                                <Col><h3>{tournamentContext.tournamentEntity?.tournament.name}</h3>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col><h5>{tournamentContext.tournamentEntity?.tournament.location}</h5>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer>
                                    <Row>
                                        <Col className={"text-center"}>
                                            <Button onClick={() => window.location.href = "/order"}>Weiter</Button>
                                        </Col>
                                    </Row>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </>
            }
        </Container>
    );
}

export default MainPage;