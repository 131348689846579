import {Alert, Button, Col, Container, FloatingLabel, Form, Modal, ModalBody, Row} from "react-bootstrap";
import React, {ChangeEvent, useState} from "react";
import {AddressRecommendation, DokoOrderRequest, DokoOrderResponse, DokoUser} from "../../../lambda/common/Entities";
import {useTournamentContext} from "../context/TournamentContext";
import * as EmailValidator from "email-validator"
import moment from "moment";
import {AsyncTypeahead, Hint} from "react-bootstrap-typeahead";
import {Option} from "react-bootstrap-typeahead/types/types";
import {Place} from "@aws-sdk/client-location";

export default function DokoUserComponent(props: {
    onAddTicket(dokoUser: DokoUser): void
    onCancel(): void
    visible: boolean
}) {
    function createEmptyDokoUser() {
        return {
            firstname: "",
            lastname: "",
            birthdate: "",
            email: "",
            gender: "m",
            address: "",
            city: "",
            zipCode: "",
            communicationAllowed: false
        } as DokoUser;
    }

    const participantUnder18Text = `Für Teilnehmer ab 14 Jahren und unter 18 Jahren ist eine schriftliche Erlaubnis der Erziehungsberechtigten und (falls der/die Erziehungsberechtigten den Jugendlichen nicht selbst begleiten) eine von den Erziehungsberechtigten bestimmte volljährige Aufsichtsperson erforderlich. Teilnehmer unter 18 Jahren sind von der Teilnahme an der Verlosung des Sonderpreises ausgeschlossen.`


    const tournamentContext = useTournamentContext()
    const [dokoUser, setDokoUser] = useState(createEmptyDokoUser())
    const [validationEntries, setValidationEntries] = useState<string[]>([])
    const [participantUnder18Visible, setParticipantUnder18Visible] = useState<boolean>(false)
    const [loadingAddresses, setLoadingAddresses] = useState<boolean>(false)
    const [addressRecommendations, setAddressRecommendations] = useState<Place[]>([])
    const [selectedAddress, setSelectedAddress] = useState<Place[]>([] as Place[])

    function validate() {
        let valid = true;
        const validEntries = [] as string[]
        if (!dokoUser.firstname || dokoUser.firstname === "") {
            valid = false
            validEntries.push("Vorname")
        }
        if (!dokoUser.lastname || dokoUser.lastname === "") {
            valid = false
            validEntries.push("Nachname")
        }
        let birthdate = dokoUser.birthdate;
        if (!birthdate) {
            valid = false
            validEntries.push("Geburtstag")
        }
        if (!dokoUser.email || dokoUser.email === "" || !EmailValidator.validate(dokoUser.email)) {
            valid = false
            validEntries.push("E-Mail Adresse")
        }
        if (!dokoUser.city || dokoUser.city === "") {
            valid = false
            validEntries.push("Stadt")
        }
        if (!dokoUser.zipCode || dokoUser.zipCode === "" || isNaN(+dokoUser.zipCode)) {
            valid = false
            validEntries.push("PLZ (bitte nur Zahlen eingeben)")
        }
        if (!dokoUser.address || dokoUser.address === "") {
            valid = false
            validEntries.push("Straße & Hausnummer")
        }
        if (valid) {
            const birthdate = new Date(dokoUser.birthdate)
            let dateOver18 = new Date(birthdate.getFullYear() + 18, birthdate.getMonth(), birthdate.getDate());
            let dateOver14 = new Date(birthdate.getFullYear() + 14, birthdate.getMonth(), birthdate.getDate());
            let tournamentDate = new Date(tournamentContext.tournamentEntity?.tournament.date!);
            if (dateOver18 >= tournamentDate) {
                if (dateOver14 <= tournamentDate) {
                    setParticipantUnder18Visible(true)
                    valid = false
                } else {
                    valid = false
                    validEntries.push("Zur Teilnahme müssen Sie mindestens 14 Jahre alt sein.")
                }
            }
        }
        if (!valid) {
            setValidationEntries(validEntries)
        }
        return valid;
    }

    function closeModalSuccess() {
        const dokoUserParam = dokoUser
        setDokoUser(createEmptyDokoUser())
        setSelectedAddress([])
        setAddressRecommendations([])
        props.onAddTicket(dokoUserParam)
    }

    async function loadAddressRecommendations(searchText: string) {
        setLoadingAddresses(true)
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/api/public/participant/address/search?searchText=" + searchText, {
                method: "GET",
            })
            if (!response || response.status !== 200) {
                console.log("Error fetching addresses")
            }
            try {
                const addresses = await response.json() as any[]
                setAddressRecommendations(addresses)
            } catch (e) {
                setAddressRecommendations([])
            }
        } catch (e) {
            console.log("Error fetching addresses")
            console.log(e)
            throw e;
        } finally {
            setLoadingAddresses(false)
        }
    }

    function addressSelected(selected: Option[]) {
        const place = selected as Place[]
        setSelectedAddress(place)
        if (place.length > 0) {
            setDokoUser({
                ...dokoUser,
                zipCode: place[0].PostalCode || "",
                city: place[0].Municipality || "",
                address: place[0].Street + " " + place[0].AddressNumber
            })
        } else {
            setDokoUser({
                ...dokoUser,
                address: ""
            })
            setDokoUser({...dokoUser, zipCode: ""})
            setDokoUser({...dokoUser, city: ""})
        }
    }

    return (
        <>
            <Modal show={participantUnder18Visible}>
                <Modal.Header>
                    <Modal.Title>Teilnehmer nicht volljährig</Modal.Title>
                </Modal.Header>
                <ModalBody>
                    <h4>{participantUnder18Text}</h4>
                </ModalBody>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setParticipantUnder18Visible(false)
                    }}>
                        Abbrechen
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setParticipantUnder18Visible(false)
                        closeModalSuccess();
                    }}>
                        Bestellung abschließen
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={props.visible && !participantUnder18Visible}
                   backdrop="static"
                   keyboard={false}
                   scrollable={true}>
                <Modal.Header>
                    <Modal.Title>Daten des Teilnehmers
                        für {tournamentContext.tournamentEntity?.tournament.shortDisplayName}</Modal.Title>
                </Modal.Header>
                <ModalBody>
                    {validationEntries.length > 0 &&
                        <Alert variant={"danger"}>
                            Bitte überprüfen / ergänzen Sie folgenden Angaben:
                            <ul>
                                {validationEntries.map(value => {
                                    return <li key={value}>{value}</li>
                                })}
                            </ul>
                        </Alert>
                    }
                    <Container fluid={false}>
                        <Row className="mt-2">
                            <Col className="col-12 col-md-6">
                                <FloatingLabel
                                    controlId="firstname"
                                    label="Vorname"
                                    className="mb-3"
                                >
                                    <Form.Control value={dokoUser.firstname} onChange={event => {
                                        setDokoUser({...dokoUser, firstname: event.target.value})
                                    }} name="firstname" type="text" placeholder="Vorname"/>
                                </FloatingLabel>
                            </Col>
                            <Col className="col-12 col-md-6">
                                <FloatingLabel
                                    controlId="lastname"
                                    label="Nachname"
                                    className="mb-3"
                                >
                                    <Form.Control value={dokoUser.lastname} onChange={event => {
                                        setDokoUser({...dokoUser, lastname: event.target.value})
                                    }} name="lastname" type="text" placeholder="Nachname"/>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col className="col-12 col-md-6">
                                <FloatingLabel
                                    controlId="gender"
                                    label="Geschlecht"
                                    className="mb-3"
                                >
                                    <Form.Select value={dokoUser.gender} onChange={event => {
                                        setDokoUser({...dokoUser, gender: event.target.value})
                                    }} name="gender" placeholder="Geschlecht">
                                        <option value="m">männlich</option>
                                        <option value="w">weiblich</option>
                                        <option value="d">divers</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col className="col-12 col-md-6">
                                <FloatingLabel
                                    controlId="bday"
                                    label="Geburtsdatum"
                                    className="mb-3"
                                >
                                    <Form.Control value={moment(dokoUser.birthdate).format("yyyy-MM-DD")}
                                                  onChange={event => {
                                                      console.log(event.target.value)
                                                      setDokoUser({...dokoUser, birthdate: event.target.value})
                                                  }} name="bday" type="date" placeholder="Geburtstag"/>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className={"mt-2"}>
                            <Col className="col-12">
                                <FloatingLabel
                                    controlId="email"
                                    label="E-Mail Adresse"
                                    className="mb-3"
                                >
                                    <Form.Control value={dokoUser.email} onChange={event => {
                                        setDokoUser({...dokoUser, email: event.target.value})
                                    }} name="email" type="email" placeholder="E-Mail Adresse"/>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className={"mt-2"}>
                            <Col className="col-12 mb-3">
                                <AsyncTypeahead isLoading={loadingAddresses} onSearch={loadAddressRecommendations}
                                                filterBy={() => true}
                                                minLength={3}
                                                id={"address"}
                                                selected={selectedAddress}
                                                onChange={addressSelected}
                                                emptyLabel={"Keine Adresse gefunden"}
                                                onBlur={event => {
                                                    if (!selectedAddress || selectedAddress.length === 0) {
                                                        setDokoUser({...dokoUser, address: event.target.value})
                                                    }
                                                }}
                                                searchText={"Suche wird durchgeführt..."}
                                                promptText={"Zum Suchen einer Adresse bitte Straße eingeben"}
                                                labelKey={option => {
                                                    const place = option as Place
                                                    return place.Label!
                                                }}
                                                renderInput={({
                                                                  inputRef,
                                                                  referenceElementRef,
                                                                  value,
                                                                  ...inputProps
                                                              }) => (
                                                    <Hint>
                                                        <FloatingLabel controlId="floatingLabel"
                                                                       label="Straße & Hausnr.">
                                                            <Form.Control
                                                                {...inputProps}
                                                                value={value as string}
                                                                ref={(instance: HTMLInputElement) => {
                                                                    inputRef(instance)
                                                                    referenceElementRef(instance)
                                                                }}
                                                            />
                                                        </FloatingLabel>
                                                    </Hint>
                                                )}
                                                options={addressRecommendations}></AsyncTypeahead>
                            </Col>
                        </Row>
                        {selectedAddress.length == 0 && <Row className={"mt-2"}>
                            <Col className="col-12 col-md-6">
                                <FloatingLabel
                                    controlId="zipcode"
                                    label="PLZ"
                                    className="mb-3"
                                >
                                    <Form.Control value={dokoUser.zipCode} onChange={event => {
                                        setDokoUser({...dokoUser, zipCode: event.target.value})
                                    }} name="zipcode" type="text" placeholder="PLZ"/>
                                </FloatingLabel>
                            </Col>
                            <Col className="col-12 col-md-6">
                                <FloatingLabel
                                    controlId="city"
                                    label="Stadt"
                                    className="mb-3"
                                >
                                    <Form.Control value={dokoUser.city} onChange={event => {
                                        setDokoUser({...dokoUser, city: event.target.value})
                                    }} name="city" type="text" placeholder="Stadt"/>
                                </FloatingLabel>
                            </Col>
                        </Row>}
                        <Row>
                            <Col className="col-12">
                                <Form.Check
                                    type="checkbox"
                                    label='Ich erlaube dem Schützenverein "Frohsinn" Greven Ost 1925 e.V. meine Daten zur Information von zukünftigen Doppelkopfturnieren zu verwenden.'
                                    checked={dokoUser.communicationAllowed}
                                    onChange={(event) => {
                                        setDokoUser({...dokoUser, communicationAllowed: event.target.checked})
                                    }}
                                />
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setValidationEntries([])
                        setDokoUser(createEmptyDokoUser())
                        setSelectedAddress([])
                        setAddressRecommendations([])
                        props.onCancel()
                    }}>
                        Abbrechen
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setValidationEntries([])
                        if (validate()) {
                            closeModalSuccess();
                        }
                    }}>Ticket zum Warenkorb
                        hinzufügen</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
        ;
}