import React, {useEffect, useRef, useState} from "react";
import {
    Accordion,
    Alert,
    Badge,
    Button,
    Card,
    Col,
    Container,
    ListGroup,
    Modal,
    ModalBody,
    Overlay,
    Row,
    Tooltip
} from "react-bootstrap";
import {PayPalButtons} from "@paypal/react-paypal-js";
import {OnApproveData} from "@paypal/paypal-js/types/components/buttons";
import {DokoOrderRequest, DokoOrderResponse, DokoUser} from "../../../lambda/common/Entities";
import DokoUserComponent from "../components/DokoUser";
import {Calendar3, CartDashFill, Geo, PlusCircle} from "react-bootstrap-icons";
import {useTournamentContext} from "../context/TournamentContext";


function OrderTickets() {

    const buttonRef = useRef<HTMLButtonElement | null>(null)
    const tournamentContext = useTournamentContext()
    const [additionalDokoUserList, setAdditionDokoUserList] = useState([] as DokoUser[])
    const [cartVisible, setCartVisible] = useState(false)
    const [startCheckout, setStartCheckout] = useState(false)
    const [showSeveralTicketsTooltipVisible, setShowSeveralTicketsTooltipVisible] = useState(false)
    const [addTicketVisible, setAddTicketVisible] = useState(false)
    const [noRefundDisclaimerVisible, setNoRefundDisclaimerVisible] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const dayNames = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"]

    async function createOrder() {
        setErrorMessage("")
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/api/public/order/create", {
                method: "POST",
                body: JSON.stringify({
                    participants: additionalDokoUserList
                } as DokoOrderRequest)
            })
            if (!response || response.status !== 200) {
                setErrorMessage("Fehler beim Bestellvorgang. Bitte versuchen Sie es erneut.")
            }
            const dokoOrder = await response.json() as DokoOrderResponse
            return dokoOrder.orderId
        } catch (e) {
            setErrorMessage("Fehler beim Bestellvorgang. Bitte versuchen Sie es erneut.")
            throw e;
        }
    }

    async function onApprove(data: OnApproveData) {
        setErrorMessage("")
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/api/public/order/capture", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "orderId": data.orderID,
                    "tournamentId": tournamentContext.tournamentEntity?.tournament.id
                })
            })
            if (!response || response.status !== 200) {
                setErrorMessage("Fehler beim Bestellvorgang. Bitte versuchen Sie es erneut.")
                return
            }
            setAdditionDokoUserList([] as DokoUser[])
            setStartCheckout(false)
            window.location.href = "/orderDone?orderId=" + data.orderID
        } catch (e) {
            setErrorMessage("Fehler beim Bestellvorgang. Bitte versuchen Sie es erneut.")
            throw e;
        }
    }

    async function addTicket() {
        setErrorMessage("")
        if (additionalDokoUserList.length >= 5) {
            setErrorMessage("Sie können in einer Bestellung maximal 5 Tickets gleichzeit kaufen.")
        } else {
            setShowSeveralTicketsTooltipVisible(false)
            setAddTicketVisible(true)
        }
    }

    let noRefundText = <>Dieses Ticket sichert Ihnen einen Platz
        für das {tournamentContext.tournamentEntity?.tournament.displayName}.
        Ihr Platz ist bis 15 Minuten vor Veranstaltungsbeginn für sie reserviert. Im Anschluss daran
        verfällt ihr
        Anspruch auf die Teilnahme am Turnier.<br/><br/>
        Mit dem Klick auf "Weiter zur Kasse" ist eine Rückerstattung des Startgeldes generell
        ausgeschlossen.</>;

    function closeAddUserModelSuccess(dokoUser: DokoUser) {
        if (additionalDokoUserList.length == 0) {
            setShowSeveralTicketsTooltipVisible(true)
        }
        additionalDokoUserList.push(dokoUser)
        setAddTicketVisible(false)
    }

    useEffect(() => {
        if (tournamentContext.isBooked() || !tournamentContext.isReservationOpened() || tournamentContext.isReservationClosed()) {
            window.location.href = "/"
        }
    }, []);

    return (
        <>
            <Container fluid={true}>
                {errorMessage != "" && <Alert variant={"danger"}>{errorMessage}</Alert>}
                <Row>
                    <Col><strong>{tournamentContext.tournamentEntity?.tournament.name}</strong></Col>
                </Row>
                <Row>
                    <Col>
                        <>
                            <Calendar3 style={{
                                marginTop: "-4px",
                                marginRight: "15px"
                            }}/>{dayNames[new Date(tournamentContext.tournamentEntity?.tournament.date!).getDay()] + ", " + new Date(tournamentContext.tournamentEntity?.tournament.date!).getDate() + ". " + new Date(tournamentContext.tournamentEntity?.tournament.date!).toLocaleString('de-DE', {
                            month: 'long'
                        }) + " | " + tournamentContext.tournamentEntity?.tournament.time!}
                        </>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <>
                            <Geo style={{
                                marginTop: "-4px",
                                marginRight: "15px"
                            }}/>{tournamentContext.tournamentEntity?.tournament.location}</>
                    </Col>
                </Row>
            </Container>
            <hr/>
            <Container fluid={false}>
                {!startCheckout && !cartVisible && <>
                    <Row className="justify-content-center align-items-center mt-3">
                        <Col className={"col-12"}>
                            <Card className={"shadow-sm"}>
                                <Card.Body>
                                    <Row>
                                        <Col><strong>Ticket
                                            für {tournamentContext.tournamentEntity?.tournament.shortDisplayName}</strong>
                                        </Col>
                                    </Row>
                                    <Row className={"mt-4"}>
                                        <Col className={"col-auto me-auto my-auto"}>
                                            <small className={"text-secondary"}>Ticketpreis</small><br/>
                                            {"€ " + tournamentContext.tournamentEntity?.tournament.price + ",00"}
                                        </Col>
                                        <Col className={"col-auto"}>
                                            <Row>
                                                <Col className={"col-auto me-auto my-auto"}>
                                                    {additionalDokoUserList.length == 0 ? "" : additionalDokoUserList.length}
                                                </Col>
                                                <Col>
                                                    <Button ref={buttonRef} onClick={() => addTicket()}
                                                            style={{marginLeft: "10px", color: "white"}}
                                                            className={"stepperButton"}
                                                            variant={"warning"}><PlusCircle
                                                        style={{marginTop: "-3px", marginLeft: "0.5px"}}/>
                                                    </Button>
                                                    <Overlay target={buttonRef.current}
                                                             show={showSeveralTicketsTooltipVisible} placement="left">
                                                        <Tooltip className={"clickable"} onClick={() => {
                                                            setShowSeveralTicketsTooltipVisible(false)
                                                        }}>
                                                            Sie können mit einer Bestellung bis zu 5 Karten auf einmal
                                                            bestellen.
                                                        </Tooltip>
                                                    </Overlay>

                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    {(additionalDokoUserList.length > 0 && !startCheckout) && <Row className="mt-4">
                                        <Col className="col-12">
                                            <Button className={"btn-lg w-100"} onClick={() => {
                                                setNoRefundDisclaimerVisible(true)
                                            }}><CartDashFill style={{
                                                marginTop: "-5px",
                                                marginRight: "10px"
                                            }}/>{additionalDokoUserList.length + " Ticket" + ((additionalDokoUserList.length > 1) ? "s" : "") + ", € " + tournamentContext.tournamentEntity?.tournament.price! * additionalDokoUserList.length + ",00"}
                                            </Button>
                                        </Col>
                                    </Row>}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className={"mt-5"}>
                        <Accordion>
                            <Accordion.Item eventKey="0" className={"shadow-sm"}>
                                <Accordion.Header><strong>Versand</strong></Accordion.Header>
                                <Accordion.Body>
                                    Am Abend des Turniers erhalten Sie, mit dem in der E-Mail vorhandenen QR-Code, bei
                                    der Anmeldung Ihre Spielerkarte.<br/>
                                    Zusätzlich haben Sie die Möglichkeit Ihre Karte auf Ihrem iPhone in der Apple Wallet
                                    App zu speichern.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1" className={"shadow-sm"}>
                                <Accordion.Header><strong>Informationen zur Buchung</strong></Accordion.Header>
                                <Accordion.Body>
                                    {noRefundText}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Row>
                </>}
                {cartVisible &&
                    <>
                        <Row>
                            <p className={"display-3"}>Bestellübersicht</p>
                        </Row>
                        <Row className="mt-3">
                            <Col className="col-auto me-auto">
                                <h5>Warenkorb</h5>
                            </Col>
                            <Col className={"col-auto my-auto"}>
                                <h5 className={"text-info"}>
                                    {additionalDokoUserList.length} Karte{additionalDokoUserList.length > 1 ? "n" : ""},
                                    € {additionalDokoUserList.length * tournamentContext.tournamentEntity?.tournament.price!},00
                                </h5>
                            </Col>
                        </Row>
                        <Row className={"mt-3"}>
                            <Col>
                                <ListGroup className={"shadow-sm"}>
                                    {additionalDokoUserList.map((dokoUser) => {
                                        return <ListGroup.Item key={dokoUser.email} as="li"
                                                               className="d-flex justify-content-between align-items-start">
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">{dokoUser.firstname} {dokoUser.lastname}</div>
                                                {dokoUser.email}
                                            </div>
                                            <Badge bg="primary" pill>
                                                {tournamentContext.tournamentEntity?.tournament.price} €
                                            </Badge>
                                        </ListGroup.Item>
                                    })}
                                </ListGroup>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                <Card className={"shadow-sm"}>
                                    <Card.Body>
                                        <Row>
                                            <Col><strong>Bestellübersicht</strong></Col>
                                        </Row>
                                        <Row>
                                            <Col className={"col-auto me-auto"}>{additionalDokoUserList.length} x Ticket
                                                für {tournamentContext.tournamentEntity?.tournament.shortDisplayName}</Col>
                                            <Col
                                                className={"col-auto"}>€ {additionalDokoUserList.length * tournamentContext.tournamentEntity?.tournament.price!},00</Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <small>{tournamentContext.tournamentEntity?.tournament.location!}</small>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <small>{dayNames[new Date(tournamentContext.tournamentEntity?.tournament.date!).getDay()] + ", " + new Date(tournamentContext.tournamentEntity?.tournament.date!).toLocaleString('de-DE', {
                                                    month: 'long'
                                                }) + " | " + tournamentContext.tournamentEntity?.tournament.time!}</small>
                                            </Col>
                                        </Row>
                                        <Row className={"mt-4"}>
                                            <Col>
                                                <Button className={"btn-lg w-100"} onClick={() => {
                                                    setCartVisible(false)
                                                    setStartCheckout(true)
                                                }}>Weiter zur Kasse</Button>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </>}
                {startCheckout &&
                    <>
                        <Row className="mt-3 text-center">
                            <Col>
                                <p className={"display-5"}>
                                    Bitte wählen Sie ihre Zahlungsart aus...
                                </p>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col className="col-12 d-flex justify-content-center">
                                <div className="paypal">
                                    <PayPalButtons className=""
                                                   createOrder={createOrder}
                                                   onApprove={onApprove}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </>
                }
                <Modal show={noRefundDisclaimerVisible}>
                    <Modal.Header>
                        <Modal.Title>Ticket verfällt 15 Minuten vor Veranstaltungsbeginn</Modal.Title>
                    </Modal.Header>
                    <ModalBody>
                        <h4>
                            {noRefundText}
                        </h4>
                    </ModalBody>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            setNoRefundDisclaimerVisible(false)
                        }}>
                            Abbrechen
                        </Button>
                        <Button variant="primary" onClick={() => {
                            setNoRefundDisclaimerVisible(false)
                            setCartVisible(true)
                        }}>
                            Bestellung abschließen
                        </Button>
                    </Modal.Footer>
                </Modal>
                <DokoUserComponent onAddTicket={dokoUser => {
                    closeAddUserModelSuccess(dokoUser);
                }} onCancel={() => setAddTicketVisible(false)} visible={addTicketVisible}></DokoUserComponent>
            </Container>
        </>
    )
        ;
}

export default OrderTickets;