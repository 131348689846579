import App from "./App";

import * as React from "react";
import ReactDOM from "react-dom/client";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Helmet} from "react-helmet";
import {PayPalScriptProvider} from "@paypal/react-paypal-js";
import 'react-loading-skeleton/dist/skeleton.css'
import TournamentProvider from "./context/TournamentContext";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

const initialOptions = {
    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID!,
    currency: "EUR",
    intent: "capture",
};

root.render(
    <React.StrictMode>
        <Helmet>
            <link rel="stylesheet"
                  href="https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@v3.0.0-rc.17/dist/cookieconsent.css"/>
            <script type="module" src="/cookieconsent-config.js"></script>
            <meta name="Doppelkopf Turnier Frohsinn Greven Ost 1925 e.V."/>
            <title>{process.env.REACT_APP_ENV + "Doppelkopf Turnier Frohsinn Greven Ost 1925 e.V."}</title>
        </Helmet>
        <PayPalScriptProvider options={initialOptions}>
            <TournamentProvider>
                <App/>
            </TournamentProvider>
        </PayPalScriptProvider>
    </React.StrictMode>
);